<template>
    <v-sheet>
        <v-toolbar dense flat dark color="primary">
            <v-toolbar-title>Comunicados de ARCHIVO CLÍNICO</v-toolbar-title>
            <v-spacer/>
            <v-btn icon>
                <v-icon>
                    mdi-plus
                </v-icon>
            </v-btn>
        </v-toolbar>
        <v-alert class="ma-5" type="warning" text>
            No se han agregado comunicados aún
        </v-alert>
        <div class="py-5">

        </div>
    </v-sheet>
</template>

<script>
export default {

}
</script>

<style>

</style>